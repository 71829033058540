<template>
  <v-dialog
    content-class="dialog-editar-placa"
    :value="mostrar"
    @click:outside="$emit('fechar')"
  >
    <v-card>
      <v-toolbar
        color="grey lighten-3"
        flat
        dense
      >
        <v-toolbar-title>
          Digite a placa correta
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            text
            small
            @click="$emit('fechar')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 pb-1 text-center">
        <v-row>
          <v-col class="px-0">
            <v-text-field
              v-mask="'XXXXXXX'"
              :value="placaEditar.toUpperCase()"
              label="Placa correta"
              dense
              outlined
              hide-details="auto"
              autofocus
              @input="placaEditar = $event.toUpperCase()"
            />
          </v-col>
        </v-row>

        <v-row v-if="infoCortex !== null">
          <v-col class="text-left">
            <h1 class="display-1 mb-2">
              Resultado Cortex:
            </h1>

            <div class="body-1 font-weight-light">
              <p class="ma-0">
                <b>Placa:</b> {{ infoCortex.placa }}
              </p>
              <p class="ma-0">
                <b>Município:</b> {{ `${infoCortex.municipioPlaca}/${infoCortex.ufEmplacamento}` }}
              </p>
              <p class="ma-0">
                <b>Ano:</b> {{ `${infoCortex.anoFabricacao}/${infoCortex.anoModelo}` }}
              </p>
              <p class="ma-0">
                <b>Marca/Modelo:</b> {{ infoCortex.marcaModelo }}
              </p>
              <p class="ma-0">
                <b>Tipo Veículo:</b> {{ infoCortex.tipoVeiculo }}
              </p>
              <p class="ma-0">
                <b>Cor:</b> {{ infoCortex.cor }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="6"
            class="text-left"
          >
            <v-btn
              outlined
              small
              color="info"
              :loading="carregandoBuscarPlacaCortex"
              @click="buscarPlacaCortex()"
            >
              <v-icon
                left
                small
                v-text="'mdi-information'"
              />
              Buscar Placa Cortex
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="pt-3 pt-md-0 text-right"
          >
            <v-btn
              outlined
              color="grey"
              class="mr-2"
              @click="$emit('fechar')"
            >
              <v-icon
                left
                v-text="'mdi-cancel'"
              />
              Cancelar
            </v-btn>
            <v-btn
              color="success"
              outlined
              :loading="carregandoEditarPlaca"
              @click="editarPlaca"
            >
              <v-icon
                left
                v-text="'mdi-content-save'"
              />
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import apiLeituras from '@/api/leituras'
  import apiCortex from '@/api/cortex'

  export default {

    props: {
      mostrar: {
        type: Boolean,
        default: false,
      },
      leitura: {
        type: Object,
        default: () => {},
      },
    },

    data () {
      return {
        carregandoEditarPlaca: false,
        carregandoBuscarPlacaCortex: false,
        placaEditar: '',
        infoCortex: null,
      }
    },

    methods: {

      async editarPlaca () {
        try {
          this.carregandoEditarPlaca = true

          await apiLeituras.corrigirPlaca(this.leitura.id, {
            placa: this.placaEditar,
          })

          this.$emit('placa-alterada', this.placaEditar)

          this.placaEditar = ''

          this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_editar') })
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregandoEditarPlaca = false
        }
      },

      async buscarPlacaCortex () {
        try {
          this.carregandoBuscarPlacaCortex = true
          this.infoCortex = null

          if (!this.placaEditar) {
            this.$snackbar.mostrar({ cor: '', mensagem: 'Sem placa para consultar' })
            return
          }

          const resposta = await apiCortex.buscarPlaca(this.placaEditar)

          if (resposta.data.http_response === 200) {
            this.infoCortex = resposta.data.resultado
          } else if (resposta.data.http_response === 404) {
            this.$snackbar.mostrar({ cor: 'warning', mensagem: 'Placa não encontrada' })
          } else {
            throw new Error()
          }
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregandoBuscarPlacaCortex = false
        }
      },

    },
  }
</script>

<style lang="css">
  .dialog-editar-placa {
    position: absolute;
    top: 0;
  }

  .dialog-editar-placa {
    width: 90%;
  }

  @media screen and (min-width: 768px) {
    .dialog-editar-placa {
      width: 40% !important;
    }
  }
</style>
